<template>
  <div class="m-3">
    <div class="mb-2">
      <small>откуда</small>
      <div>Королев KRL12</div>
    </div>
    <div class="mb-2">
      <small>куда</small>
      <select
        class="form-control form-control-sm shadow-none col-md-6"
        v-model="polu"
      >
        <option v-for="(item, idx) in pvz" :key="idx" :value="item.city_code">{{
          `${item.city} ${item.code} ${item.region}`
        }}</option>
      </select>
    </div>
    <div class="mb-2">
      <div>размеры и вес посылки</div>
      <div>
        <button
          class="btn btn-sm btn-outline-success m-1 shadow-none"
          @click="set(1000, 10, 10, 10)"
        >
          1000 гр. | 10*10*10
        </button>
        <button
          class="btn btn-sm btn-outline-success m-1 shadow-none"
          @click="set(1500, 10, 20, 10)"
        >
          1500 гр. | 10*20*10
        </button>
        <button
          class="btn btn-sm btn-outline-success m-1 shadow-none"
          @click="set(1500, 20, 20, 20)"
        >
          1500 гр. | 20*20*20
        </button>
        <button
          class="btn btn-sm btn-outline-success m-1 shadow-none"
          @click="set(1500, 0, 0, 0)"
        >
          1500 гр. | 0*0*0
        </button>
      </div>
      <div>
        <button
          class="btn btn-sm btn-outline-success m-1 shadow-none"
          @click="set(500, 10, 10, 10)"
        >
          500 гр. | 10*10*10
        </button>
        <button
          class="btn btn-sm btn-outline-success m-1 shadow-none"
          @click="set(2500, 20, 20, 20)"
        >
          2500 гр. | 20*20*20
        </button>
        <button
          class="btn btn-sm btn-outline-success m-1 shadow-none"
          @click="set(500, 30, 30, 30)"
        >
          500 гр. | 30*30*30
        </button>
        <button
          class="btn btn-sm btn-outline-success m-1 shadow-none"
          @click="set(500, 0, 0, 0)"
        >
          500 гр. | 0*0*0
        </button>
        <button
          class="btn btn-sm btn-outline-success m-1 shadow-none"
          @click="set(2500, 0, 0, 0)"
        >
          2500 гр. | 0*0*0
        </button>
      </div>
    </div>
    <div v-if="tarifs">
      <div v-for="(item, idx) in tarifs" :key="idx + 't'">
        {{
          `${item.delivery_sum}р. ${item.tariff_description} ${item.tariff_name}`
        }}
      </div>
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
export default {
  name: "Cdec",
  data: function() {
    return {
      pvz: null,
      tarifs: null,
      otpr: 205,
      polu: null,
      weight: null,
      length: null,
      width: null,
      height: null
    };
  },
  mounted() {
    this.get_list_sities();
  },
  methods: {
    get_list_sities() {
      HTTPGET({
        url: "template/tk/cdec/test_api/get_list_pvz.php"
      }).then(response => (this.pvz = response.data.pvz_list));
    },
    set(weight, length, width, height) {
      this.weight = weight;
      this.length = length;
      this.width = width;
      this.height = height;
      if (this.polu) {
        this.tarifs = null;
        HTTPGET({
          url: "template/tk/cdec/test_api/get_tarifs.php",
          params: {
            weight: this.weight,
            length: this.length,
            width: this.width,
            height: this.height,
            to_location: this.polu
          }
        }).then(response => (this.tarifs = response.data.tariff_codes));
      }
    }
  }
};
</script>
